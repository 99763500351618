<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-18 13:51:48
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-04-01 15:58:42
 * @Description  : 修改邮箱
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
    @close="handleClose"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("modify_email") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="form"
        status-icon
        ref="resetRef"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('email')">
          <el-input
            :placeholder="$t('please_enter_your_email_address')"
            clearable
            v-model="form.subject"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('verification_code')">
          <el-input
            v-model="form.verifyCode"
            autocomplete="off"
            maxlength="6"
            :placeholder="$t('verification_code')"
          >
            <template #append>
              <div @click="getSMSCode" class="send">
                {{ counting ? `${timerCount}s` : $t("get_verification_code") }}
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { verifyCodeModifyConcatApi, resetEmailApi } from "@/api/api.js";
import { RegexUtils } from "@/utils/validate/regex";
export default {
  name: "ChangeEmailDialog",
  data() {
    return {
      form: {
        subject: "",
        verifyCode: "",
        verifyId: "",
      },
      dialogFormVisible: false,
      timerCount: 0,
      counting: false, // 是否计时中
      interval1: null,
      yzBtnEnable: true,
    };
  },
  methods: {
    showDialog() {
      this.dialogFormVisible = true;
    },
    getSMSCode() {
      if (this.form.subject === "") {
        this.$message.error(this.$t("please_enter_your_email_address"));
        return;
      }
      if (!RegexUtils.isEmail(this.form.subject)) {
        this.$message.error(this.$t("the_email_format_is_incorrect"));

        return;
      }

      // if (!RegexUtils.checkMobile(this.form.mobileNum)) {
      // this.$message.error(this.$t("the_phone_number_format_is_incorrect"));
      //
      // return;
      // }
      // let params = `mobile=${this.mobile}`;
      let timer = 180;
      //   let toast = this.$toast.loading({
      //     message: "加载中...",
      //     forbidClick: true,
      //     duration: 0,
      //   });
      const loading = this.$loading({
        lock: true,
        text: this.$t("obtaining_verification_code"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        subject: this.form.subject,
      };

      verifyCodeModifyConcatApi(params).then((res) => {
        loading.close();
        if (res) {
          this.$message.success(this.$t("verification_code_sent_successfully"));
          this.form.verifyId = res.data;
          this.interval1 = setInterval(() => {
            timer -= 1;
            if (timer <= 0) {
              this.clearIntervalHandel();
            } else {
              this.timerCount = timer;
              this.counting = true;
              this.yzBtnEnable = false;
            }
          }, 1000);
        }
      });
    },
    clearIntervalHandel() {
      clearInterval(this.interval1);
      this.yzBtnEnable = true;
      this.counting = false;
    },
    // 添加对话框关闭处理方法
    handleClose() {
      // 清除计时器
      this.clearIntervalHandel();
      // 重置表单
      this.form = {
        subject: "",
        verifyCode: "",
        verifyId: "",
      };
    },
    handelOk() {
      if (this.form.subject === "") {
        this.$message.error(this.$t("please_enter_your_email"));
        return;
      }

      resetEmailApi(this.form).then((res) => {
        if (res) {
          this.$message.success(this.$t("modify_success"));
          this.dialogFormVisible = false;
          // localStorage.setItem("username", this.form.subject);
          this.$emit("ok");
        }
      });
    },
  },
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },
  beforeDestroy() {
    this.clearIntervalHandel();
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
};
</script>

<style scoped lang="less">
// @import "../Login.less";
.container {
  .title {
    display: flex;
    height: 50px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  .tab-box {
    //   height: 28px;
    //   border-radius: 18px;
    display: flex;
    //   margin-top: 20px;
    //   margin-left: 10px;
    //   margin-right: 10px;
    width: 400px;
    background: white;
    flex-direction: row;
    //   justify-content: space-evenly;
    align-items: center;
    line-height: 48px;
    height: 48px;
  }
  .tab-item {
    //   border-radius: 18px;
    //   margin: 8px;
    font-size: 14px;
    width: 200px;
    text-align: center;

    //   padding: 0px 4px;
    justify-content: center;
    background: #edeeef;
  }

  .is-active {
    background-color: #86c6ff;
    font-size: 16px;
    font-weight: Normal;
    text-align: center;
    line-height: 48px;
    height: 48px;

    color: #ffffff;
  }
  .send {
    font-size: 14px;
    font-weight: Normal;
    text-align: center;
    color: #6da7e0;
    line-height: 18px;
    cursor: pointer;
  }
  .demo-ruleForm {
    margin-top: 20px;
  }
}
.width-100 {
  width: 100%;
}
.forms {
  width: 70%;
  margin: 0 auto;
}
</style>
